import React, { useState, useRef } from "react";
import Chart from "react-google-charts";
//import { useScrollPosition } from "../hooks/useScrollPosition";

const GradeDistGraph = props => {
  let vAxis = useState({ title: "# of Students with Grade" });
  const [graphStyle] = useState("staticGraph");
  const graphEl = useRef(null);
  /*
  useScrollPosition(({ currPos }) => {
    let nextStyle = "staticGraph";
    if (currPos.y < -185) {
      nextStyle = "stickyGraph";
    }
    setGraphStyle(nextStyle);
  }, []);
*/
  const getData = gmod => {
    let grades = props.course.GradeList.filter(i => {
      return i.registeredGmodCode === gmod;
    });
    let showProcessing = props.course.CourseInfo.holdGrades === 'Y'
    let arr = null;

    if (showProcessing) {
      arr = [["Grade", "Submitted", "Not Submitted", "Processing", {role: "annotation"}]];
    }
    else
      arr = [["Grade", "Submitted", "Not Submitted", {role: "annotation"}]];


    if (grades.length > 0 && grades[0].gradeCodes !== null) {
      let gradecodes = grades[0].gradeCodes
        .split(",")
        .slice(1)
        .reverse();
      let sub = grades.filter(i => {
        return i.submittedGrdeCode !== null;
      }).length;
      let sav = grades.filter(i => {
        return i.savedGrdeCode !== null;
      }).length;
      let vmax = sub + sav === 0 ? grades.length : sub + sav;
      vAxis = {
        title: "# of Students with Grade",
        viewWindowMode: "explicit",
        viewWindow: { max: vmax, min: 0 }
      };

      gradecodes.forEach(grade => {
        let unsubmitted = grades.filter(i => {
          return i.submittedGrdeCode === null && i.savedGrdeCode === grade && i.pendingStatus !== 'P';
        }).length;
        let submitted = grades.filter(i => {
          return i.submittedGrdeCode === grade;
        }).length;
        let processing = grades.filter(i => {
          return i.submittedGrdeCode === null && i.savedGrdeCode === grade && i.pendingStatus === 'P';
        }).length;
        let total = submitted + unsubmitted + processing;
        if (showProcessing)
          arr = [...arr, [grade, submitted, unsubmitted, processing, total]];
        else
          arr = [...arr, [grade, submitted, unsubmitted, total]];

      });
      return arr;
    }
    return null;
  };

  return (
    <div
      ref={graphEl}
      className={graphStyle}
      id="gdistchart"
      aria-hidden={"true"}
    >
      <Chart
        width={"700px"}
        //height={"200px"}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={getData(props.gmode)}
        options={{
          title: "Grade Distribution",
          isStacked: true,
          height: 300,
          legend: { position: "right", maxLines: 3 },
          vAxis: vAxis,
          chartArea: { width: "60%", height: "70%" },
          hAxis: {
            title: "Based on submitted grades not converted or changed grades."
          }
        }}
      />
    </div>
  );
};

export default GradeDistGraph;

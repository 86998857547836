/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import { useParams, NavLink, Link } from "react-router-dom";
import Error from "../components/error";
import { BreadcrumbTitle, PageHeader, YaleNav } from "../layout/index";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GradeDistGraph from "../components/gradeDistGraph";
import useGradeEntry from "../hooks/useGradeEntry";
import useSortableTable from "../hooks/useSortableTable";
import SortableColumnHeader from "../components/sortableColumnHeader";
import ShortCourseTitle from "../components/shortCourseTitle";
import InstructorList from "../components/instructorList";
import { AuthContext } from "../context";

const Course = props => {
  let { termcode, courseid } = useParams();
  const [
    course,
    submitSchools,
    loading,
    showSubmit,
    showGrade,
    showOptionalGrade,
    canSubmitGrades,
    submitGrades,
    returnGrades,
    handleShowSubmit,
    handleCloseSubmit,
    updateSubmitSchools,
    gradeModeChange,
    canApproveGrades,
    canReturnGrades,
    closeVarCredit,
    saveVarCredit,
    showCreditButton,
    canSubmitCredits,
    showCredits,
    creditsLoading,
    setCreditStudent,
    creditStudent,
    showLawAttribute
  ] = useGradeEntry({ props, termcode, courseid });

  const [sortTable, currSortCol, currSortDir] = useSortableTable(
    course.GradeList
  );
  const { inRole } = useContext(AuthContext);

  //
  // const [
  //   loadingCredits,
  //   showCredits,
  //   savingCredits,
  //   setCredits,
  //   credits,
  //   lawStudent,
  //   LawCredit
  // ] = useLawCredit({ props, termcode, courseid });

  const CourseButtons = props => {
    return (
      <>
        {inRole("ROLE_REGS") && (
          <div className={"d-flex justify-content-end"}>
            <div className={"px-2 mb-2"}>
              {canReturnGrades() && (
                <button
                  className={"btn btn-sm btn-primary"}
                  onClick={returnGrades}
                  disabled={!canReturnGrades()}
                >
                  Return&nbsp;Pending&nbsp;Grades&nbsp;to&nbsp;Instructor
                </button>
              )}
            </div>
            <div className={"mb-2"}>
              {canApproveGrades() && (
                <button
                  className={"btn btn-sm btn-primary"}
                  onClick={handleShowSubmit}
                  disabled={!canApproveGrades()}
                >
                  Approve&nbsp;Grades
                </button>
              )}
            </div>
          </div>
        )}
        <div className={"d-flex justify-content-end"}>
          <div className={"px-2 mb-2"}>
            {canSubmitGrades() && (
              <Link to={"/upload/" + termcode + "/" + courseid}>
                <button className={"btn btn-sm btn-primary"}>Upload</button>
              </Link>
            )}
          </div>
          <div className={"mb-2"}>
            {canSubmitGrades() && (
              <button
                className={"btn btn-sm btn-primary"}
                onClick={handleShowSubmit}
                disabled={!canSubmitGrades()}
              >
                Submit&nbsp;Grades&nbsp;to&nbsp;Registrar
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Grade Entry"}>
            <Link to="/courses">Course List</Link>
            <ShortCourseTitle title={course.CourseInfo.subjCrseList} />
          </BreadcrumbTitle>
        }
        navLinks={
          <YaleNav>
            <NavLink to={`/gradelist/${termcode}/${courseid}`}>
              Print Class Roster
            </NavLink>
            <NavLink to={`/gradeforms/${termcode}/${courseid}`}>Forms</NavLink>
            {(course.CourseInfo.owner === "YC" ||
              course.Schools?.map(sch => sch.collCode)?.includes("YC")) && (
              <NavLink to={`/eotreport/${termcode}/${courseid}`}>
                End of Term Report/Ind Study
              </NavLink>
            )}
          </YaleNav>
        }
        pageTitle={"Grade Entry"}
      />

      <Error />
      {course.GradeList.length === 0 && course.Totals.totalEnrolled === 1 ? (
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col"}>
              <b>Loading Course...</b>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col col-md-4"}>
                <div className={"row"}>
                  <div className={"col"}>
                    <strong>Term: {termcode}</strong>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col"}>
                    <strong>{course.CourseInfo.crseTitle}</strong>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col"}>
                    <strong>{course.CourseInfo.subjCrseList}</strong>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col mb-2"}>
                    <strong>Instructors: </strong>
                    <InstructorList instructors={course.Instructors} />
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col"}>
                    <table className={"table-borderless yltable totals-table"}>
                      <caption>
                        <strong>Course Totals</strong>
                      </caption>
                      <tbody>
                        <tr>
                          <td>Enrollment</td>
                          <td className={"float-right"}>
                            {course.Totals.totalEnrolled}
                          </td>
                        </tr>
                        {course.CourseInfo.owner !== "LW" && (
                          <>
                            <tr>
                              <td>Withdrawn</td>
                              <td className={"float-right"}>
                                {course.Totals.withdrawnCount}
                              </td>
                            </tr>
                            <tr>
                              <td>Excused / ABX:</td>
                              <td className={"float-right"}>
                                {course.Totals.excusedFromExamCount}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td>Grades Saved</td>
                          <td className={"float-right"}>
                            {course.Totals.totalGraded}
                          </td>
                        </tr>

                        {course.CourseInfo.holdGrades === "Y" ? (
                          <>
                            <tr>
                              <td>Registrar Processing</td>
                              <td className={"float-right"}>
                                {course.Totals.pendingGradesCount}
                              </td>
                            </tr>
                            <tr>
                              <td>Returned from Registrar</td>
                              <td className={"float-right"}>
                                {course.Totals.returnedGradesCount}
                              </td>
                            </tr>
                            <tr>
                              <td>Posted by Registrar</td>
                              <td className={"float-right"}>
                                {course.Totals.approvedGradesCount}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td>Grades Submitted</td>
                            <td className={"float-right"}>
                              {course.Totals.totalSubmitted}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={"col col-md-8"}>
                {course.GradeList.length === 0 ? (
                  <div />
                ) : (
                  <>
                    {course.GradeList.length > 0 && (
                      <GradeDistGraph
                        course={course}
                        gmode={course.GradeMode}
                      />
                    )}
                    <div className={"row pb-4"} aria-hidden={"true"}>
                      <div className={"col d-flex justify-content-center"}>
                        <label htmlFor={"gradeMode"}>Grade Modes:</label>
                        <div style={{ verticalAlign: "center" }}>
                          <select
                            id="gradeMode"
                            name="gradeMode"
                            className={"form-select-input ml-2"}
                            onChange={e => {
                              gradeModeChange(e);
                            }}
                          >
                            {course.GradeMods.map((g, idx) => {
                              return (
                                //<div className={"form-check"} key={idx}>
                                <option
                                  value={g.code}
                                  key={"grade-mode-" + idx}
                                  defaultValue={course.GradeMode === g.code}
                                >
                                  {g.desc}
                                </option>
                                //</div>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {course.Totals.totalEnrolled === 0 && (
            <div className={"container"}>
              <div className={"row"}>
                <div className={"col"}>
                  <div className={"alert alert-info"}>
                    No records to display.
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={"container"}>
            {course.SecurityInfo.userRole === "SINS" && (
              <div className="row">
                <div className={"col-12"}>
                  <div className={"alert alert-warning"}>
                    Only a primary instructor and department proxy can enter
                    grades.
                  </div>
                </div>
              </div>
            )}
            <div className={"row"}>
              <div className={"col-sm-12 col-lg-6"}></div>
              <div className={"col-sm-12 col-lg-6"}>
                <CourseButtons />
              </div>
            </div>
          </div>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-12"}>
                {course.GradeList.length > 0 ? (
                  <div className={"table-responsive"}>
                    <table className={"table yltable"}>
                      <caption>
                        <b className={"sr-only"}>Grade Entry Table</b>
                        <br />
                        Select column headers to sort data in ascending order.
                        Select again for descending order.
                        <br />
                        <span>
                          {" "}
                          * The original recorded grade, if different from the
                          final grade, is in brackets
                        </span>
                        {course.CourseInfo.collList.includes("LW") && (
                          <>
                            <br />
                            <span>
                              {" "}
                              &dagger; The requested number of credits, if
                              different from actual credits, is in brackets
                            </span>
                          </>
                        )}
                      </caption>
                      <thead className={"smallerfont yltable"}>
                        <tr>
                          <SortableColumnHeader
                            columnName={"savedGrdeCode"}
                            secondarySortColumn={"submittedGrdeCode"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Grade*
                          </SortableColumnHeader>
                          {course.SecurityInfo.optCreditStudents === "Y" && (
                            <SortableColumnHeader
                              columnName={"savedCompletedInd"}
                              sortTableValue={sortTable}
                              currentSortDirection={currSortDir}
                              currentSortColumn={currSortCol}
                            >
                              YC Opt
                              <br />
                              Dist Cred
                            </SortableColumnHeader>
                          )}
                          {course.CourseInfo.anonymous === "Y" && (
                            <SortableColumnHeader
                              columnName={"examId"}
                              sortTableValue={sortTable}
                              currentSortDirection={currSortDir}
                              currentSortColumn={currSortCol}
                            >
                              Exam ID
                            </SortableColumnHeader>
                          )}
                          {course.CourseInfo.collList.includes("LW") && (
                            <>
                              <th>Comments</th>
                              <th>Writing Req</th>
                            </>
                          )}
                          <SortableColumnHeader
                            columnName={"lastName"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Student Name
                          </SortableColumnHeader>
                          <SortableColumnHeader
                            columnName={"netid"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            NetID
                          </SortableColumnHeader>
                          <SortableColumnHeader
                            columnName={"bannerId"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            SID
                          </SortableColumnHeader>
                          <SortableColumnHeader
                            columnName={"collOrResdDesc"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            School
                            <br />
                            College
                          </SortableColumnHeader>
                          <SortableColumnHeader
                            columnName={"classYear"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Cls
                          </SortableColumnHeader>

                          {course.CourseInfo.collList.includes("LW") ? (
                            <SortableColumnHeader
                              columnName={"creditHours"}
                              sortTableValue={sortTable}
                              currentSortDirection={currSortDir}
                              currentSortColumn={currSortCol}
                            >
                              Course
                              <br />
                              Credits&dagger;
                            </SortableColumnHeader>
                          ) : (
                            <SortableColumnHeader
                              columnName={"primaryMajrDesc"}
                              sortTableValue={sortTable}
                              currentSortDirection={currSortDir}
                              currentSortColumn={currSortCol}
                            >
                              Major
                              <br />
                              Field
                            </SortableColumnHeader>
                          )}
                          <SortableColumnHeader
                            columnName={"submissionDeadlineDate"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Grade Due Date
                          </SortableColumnHeader>
                          <SortableColumnHeader
                            columnName={"regCourse"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Reg. Course
                          </SortableColumnHeader>
                          <SortableColumnHeader
                            columnName={"savedGrdeDate"}
                            sortTableValue={sortTable}
                            currentSortDirection={currSortDir}
                            currentSortColumn={currSortCol}
                          >
                            Save Date
                          </SortableColumnHeader>
                          {course.CourseInfo.holdGrades === "Y" && (
                            <>
                              <SortableColumnHeader
                                columnName={"pendingStatusDesc"}
                                sortTableValue={sortTable}
                                currentSortDirection={currSortDir}
                                currentSortColumn={currSortCol}
                              >
                                Status
                              </SortableColumnHeader>
                            </>
                          )}
                          <th>School Grade Policy</th>
                        </tr>
                      </thead>
                      <tbody className={"smallerfont"}>
                        {course.GradeList.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{showGrade(val, index)}</td>
                              {course.SecurityInfo.optCreditStudents === "Y" &&
                                showOptionalGrade(val, index)}
                              {course.CourseInfo.anonymous === "Y" && (
                                <td>{val.examId}</td>
                              )}
                              {course.CourseInfo.collList.includes("LW") && (
                                <>
                                  <td>
                                    {showCreditButton(course, val, index)}
                                  </td>
                                  <td>{showLawAttribute(val)}</td>
                                </>
                              )}
                              <td>
                                {val.lastName}
                                {val.lastName !== null && ","} {val.firstName}
                              </td>
                              <td>{val.netid}</td>
                              <td>{val.bannerId}</td>
                              <td>{val.collOrResdDesc}</td>
                              <td>{val.classYear}</td>

                              {course.CourseInfo.collList.includes("LW") ? (
                                <td>
                                  <>
                                    {!val.varCredits ||
                                    val.rolledCreditHours === val.varCredits ? (
                                      <>{val.creditHours}</>
                                    ) : (
                                      <>
                                        {val.rolledCreditHours} [
                                        {val.varCredits}]
                                      </>
                                    )}
                                  </>
                                </td>
                              ) : (
                                <td>{val.primaryMajrDesc}</td>
                              )}
                              <td>
                                <Moment format="MM/DD/YYYY">
                                  {val.submissionDeadlineDate}
                                </Moment>
                              </td>
                              <td>{val.regCourse}</td>
                              <td>
                                {val.savedGrdeDate && (
                                  <div>
                                    <Moment format="MM/DD/YYYY">
                                      {val.savedGrdeDate}
                                    </Moment>
                                    <br />
                                    <Moment format="hh:mm:ss A">
                                      {val.savedGrdeDate}
                                    </Moment>
                                  </div>
                                )}
                              </td>
                              {course.CourseInfo.holdGrades === "Y" && (
                                <td>{val.pendingStatusDesc}</td>
                              )}
                              <td>
                                <a
                                  target={"_blank"}
                                  rel="noopener"
                                  href={val.policyUrl}
                                >
                                  {val.registeredCollDesc}
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h2>Grading not available for this course at this time.</h2>
                )}
              </div>
            </div>
          </div>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <CourseButtons userRole={course.SecurityInfo.userRole} />
              </div>
            </div>
          </div>
          <Modal show={showSubmit} onHide={handleCloseSubmit} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ marginTop: "0px" }}>
                Submit Grades to Registrar
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {course.Schools.length > 1 && (
                <div>
                  <div>
                    <p>
                      Please select the school(s) for which you wish to submit
                      grades.
                    </p>
                  </div>
                  {course.Schools.filter(s =>
                    course.GradeList.some(
                      g =>
                        g.registeredCollDesc === s.stvcollDesc &&
                        g.savedGrdeCode &&
                        !g.submittedGrdeCode &&
                        (course.SecurityInfo.userRole === "REGS" ||
                        inRole("ROLE_REGS")
                          ? g.pendingStatus === "P" ||
                            g.pendingStatus === null ||
                            g.pendingStatus === "R"
                          : g.pendingStatus === null || g.pendingStatus === "R")
                    )
                  ).map((schl, idx) => {
                    return (
                      <div className={"form-check"} key={idx}>
                        <input
                          className={"form-check-input mr-2"}
                          type="checkbox"
                          value={schl.collCode}
                          onChange={e => {
                            updateSubmitSchools(e);
                          }}
                        />
                        <label className={"form-check-label"}>
                          {schl.stvcollDesc}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
              {course.Schools.length === 1 && (
                <div>Submitting grades for {course.Schools[0].stvcollDesc}</div>
              )}
              {submitSchools.length === 0 && course.Schools.length > 1 && (
                <div className={"alert alert-warning"}>
                  You must select at least one school in order to submit grades.
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              {!loading && (
                <Button variant="secondary" onClick={handleCloseSubmit}>
                  Close
                </Button>
              )}
              {loading ? (
                <Button disabled variant="primary">
                  Saving Please Wait...
                </Button>
              ) : (
                <Button
                  variant="primary"
                  disabled={submitSchools.length < 1}
                  onClick={submitGrades}
                >
                  Submit
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <Modal show={showCredits} onHide={closeVarCredit} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ marginTop: "0px" }}>
                Grade Comments
                {creditStudent !== null && (
                  <span>
                    {" "}
                    for {creditStudent?.firstName} {creditStudent?.lastName}
                  </span>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={"row"}>
                <div className={"col"}>
                  <strong>Term: {termcode}</strong>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col"}>
                  <strong>{course.CourseInfo.crseTitle}</strong>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col"}>
                  <strong>{course.CourseInfo.subjCrseList}</strong>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col"}>
                  <strong>CRN: {course.CourseInfo.crnList}</strong>
                </div>
              </div>
              <div className={"row my-2"}>
                <div className={"col"}>
                  <hr />
                </div>
              </div>
              <div className={"row my-2"}>
                {creditsLoading ? (
                  <div>
                    <b>Loading Credits...</b>
                  </div>
                ) : (
                  <div className={"col"}>
                    In this course, the student has fulfilled the requirements
                    for
                    <div className={"form-check"}>
                      <label className={"form-check-label"}>
                        <input
                          name={"additionalCreditCode"}
                          checked={
                            creditStudent?.additionalCreditCode === "LSUB"
                          }
                          className={"form-check-input"}
                          type={"radio"}
                          value={"LSUB"}
                          onChange={e => {
                            setCreditStudent({
                              ...creditStudent,
                              additionalCreditCode: e.target.value
                            });
                          }}
                        />
                        Substantial Paper Credit
                      </label>
                    </div>
                    <div className={"form-check"}>
                      <label className={"form-check-label"}>
                        <input
                          name={"additionalCreditCode"}
                          checked={
                            creditStudent?.additionalCreditCode === "LSAW"
                          }
                          className={"form-check-input"}
                          type={"radio"}
                          value={"LSAW"}
                          onChange={e => {
                            setCreditStudent({
                              ...creditStudent,
                              additionalCreditCode: e.target.value
                            });
                          }}
                        />
                        Supervised Analytical Writing
                      </label>
                    </div>
                    <div className={"form-check"}>
                      <label className={"form-check-label"}>
                        <input
                          name={"additionalCreditCode"}
                          checked={
                            creditStudent?.additionalCreditCode === "NONE"
                          }
                          className={"form-check-input"}
                          type={"radio"}
                          value={"NONE"}
                          onChange={e => {
                            setCreditStudent({
                              ...creditStudent,
                              additionalCreditCode: e.target.value
                            });
                          }}
                        />
                        N/A
                      </label>
                    </div>
                    <div className={"row my-2"}>
                      <div className={"col"}>
                        <hr />
                      </div>
                    </div>
                    <div className={"row my-2"}>
                      <div className={"col"}>
                        Number of credits the student should be awarded:
                        <input
                          type={"text"}
                          className="form-control"
                          id="varCredits"
                          maxLength={3}
                          value={
                            creditStudent?.varCredits == null
                              ? ""
                              : creditStudent?.varCredits
                          }
                          onChange={e => {
                            setCreditStudent({
                              ...creditStudent,
                              varCredits: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={"row my-2"}>
                      <div className={"col"}>
                        <hr />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!creditsLoading && (
                <Button variant="secondary" onClick={closeVarCredit}>
                  Close
                </Button>
              )}
              {creditsLoading ? (
                <Button disabled variant="primary">
                  Saving Please Wait...
                </Button>
              ) : (
                <Button
                  variant="primary"
                  disabled={!canSubmitCredits()}
                  onClick={() => {
                    saveVarCredit();
                  }}
                >
                  Submit
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Course;

import { useState } from "react";

export const SORT_ASC = "asc";
export const SORT_DESC = "desc";

const useSortableTable = rows => {
  const [currSortDir, setCurrSortDir] = useState(SORT_ASC);
  const [currSortCol, setCurrSortCol] = useState("");

  const sortTable = (column_name, secondary_column_name) => {
    let sortDir = currSortDir;
    if (currSortCol !== column_name) {
      //  Always start new columns ascending
      sortDir = SORT_ASC;
    } else if (currSortDir === "desc") {
      sortDir = SORT_ASC;
    } else {
      sortDir = SORT_DESC;
    }
    setCurrSortCol(column_name);
    setCurrSortDir(sortDir);

    rows.sort(comparer(column_name, sortDir, secondary_column_name));
  };

  const comparer = (key, order, secondary_key) => {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const A = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const B = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      if ((A === B) && (secondary_key !== null)) {
        const SA = typeof a[secondary_key] === "string" ? a[secondary_key].toUpperCase() : a[secondary_key];
        const SB = typeof b[secondary_key] === "string" ? b[secondary_key].toUpperCase() : b[secondary_key];

        return compareItems(SA, SB, order);

      }
      else {
        return compareItems(A, B, order);
      }

    };
  };

  const compareItems = (a, b, order) => {
    let comparison = 0;

    if (a === b)
      return 0;
    else if (a === null) comparison = 1;
    // Nulls sort first ascending
    else if (b === null) comparison = -1;
    else if (a > b) comparison = 1;
    else if (a < b) comparison = -1;

    return order === SORT_DESC ? comparison * -1 : comparison;

  }

  return [sortTable, currSortCol, currSortDir];
};

export default useSortableTable;
